import { Affix, Col, Row } from 'antd';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DatesBar } from './DatesBar';
import { BlackoutDate, TimeSlots } from './TimeSlots';
import { useViewSlotsContainerContext } from './ViewSlotsContainer';
import { ListBlackoutDates } from '../../../../GraphQL';

const Styles = styled.div`
  .row-wrapper.ant-row-flex {
    padding-top: 0;
  }

  .dates-bar {
    margin-bottom: 1em;
  }

  .time-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;

    &.dash-btn {
      border: 0;
      font-weight: bold;
      box-shadow: none;
    }
  }
`;

const DATE_FORMAT = 'MM/DD/YYYY';

const GET_OWN_SLOTS_QUERY = gql`
  query GetOwnSlots(
    $profileId: ID!
    $procedureId: ID!
    $start: String
    $patientIsPreAuthorized: Boolean
    $searchType: SearchType
  ) {
    GetOwnSlots(
      profileId: $profileId
      procedureId: $procedureId
      start: $start
      patientIsPreAuthorized: $patientIsPreAuthorized
      originator: "get_own_slots_query"
      searchType: $searchType
    ) {
      id
      status
      start
      end
      slotIdsForAppointment
      serviceType {
        coding {
          code
          display
          system
          version
        }
      }
    }
  }
`;

interface CodingInterface {
  system: string;
  version: string;
  code: string;
  display: string;
  userSelected: boolean;
}

interface CodeableConceptInterface {
  text: string;
  coding: CodingInterface[];
}

export interface SlotsInterface {
  id: string;
  status: string;
  start: string;
  end: string;
  slotIdsForAppointment: string[];
  serviceType: CodeableConceptInterface[];
  blackoutDateId?: string;
}

export enum SearchType {
  All = 'ALL',
  Consumer = 'CONSUMER',
  Referral = 'REFERRAL',
}

interface Props {
  profileId: string;
  procedureId: string;
  searchType: SearchType;
  scheduleId: string;
  showToggle: boolean;
}

interface DaysMap {
  [key: string]: SlotsInterface[] | string[];
}

const computeDaysFromSlots = (slots: SlotsInterface[]): DaysMap => {
  const sortedSlots = slots.slice().sort((a, b) => {
    if (a.start < b.start) {
      return -1;
    }

    if (a.start > b.start) {
      return 1;
    }

    return 0;
  });

  return sortedSlots.reduce<DaysMap>((acc, slot) => {
    const date = moment(slot.start).format(DATE_FORMAT);
    const ids = `__ids__${date}`;

    if (!(date in acc)) {
      acc[date] = [] as SlotsInterface[];
      acc[ids] = [] as string[];
    }

    if ((acc[ids] as string[]).indexOf(slot.id) === -1) {
      (acc[date] as SlotsInterface[]).push(slot);
      (acc[ids] as string[]).push(slot.id);
    }

    return acc;
  }, {});
};

const mergeSlots = (slots: SlotsInterface[], blackoutDates: BlackoutDate[] | undefined) => {
  if (!blackoutDates) return slots;

  const blackoutSlots = blackoutDates.map(blackout => {
    const currentDate = moment(blackout.start);
    return {
      id: `blackout-${blackout.id}-${blackout.startTime}`,
      start: `${currentDate.format('YYYY-MM-DD')}T${blackout.startTime}`,
      end: `${currentDate.format('YYYY-MM-DD')}T${blackout.endTime}`,
      status: 'blackout',
      slotIdsForAppointment: [],
      serviceType: [],
      blackoutDateId: blackout.id,
    } as SlotsInterface;
  });

  return [...slots, ...blackoutSlots];
};

export const SlotsContainer: FC<Props> = ({
  profileId,
  procedureId,
  searchType,
  scheduleId,
  showToggle,
}): JSX.Element => {
  const { dates, arrowsColSpan, slotsColSpan } = useViewSlotsContainerContext();
  const [allSlots, setAllSlots] = useState<SlotsInterface[]>([]);

  const { data: blackoutData } = useQuery<{ ListBlackoutDates: BlackoutDate[] }>(ListBlackoutDates, {
    variables: {
      ListBlackoutDatesInput: {
        scheduleId: scheduleId,
        reason: 'APPOINTMENT',
      },
    },
  });

  const startDate = moment(dates[0]).format('YYYY-MM-DD');
  const { loading: loadingCurrent, error: errorCurrent, data: dataCurrent, refetch: refetchCurrent } = useQuery(
    GET_OWN_SLOTS_QUERY,
    {
      variables: {
        profileId,
        procedureId,
        start: startDate,
        searchType,
      },
      fetchPolicy: 'no-cache',
    }
  );

  // This is to fix the issue where some of the last day's slots are not being fetched correctly because of the timezone
  const nextDayDate = moment(dates[0])
    .add(1, 'days')
    .format('YYYY-MM-DD');
  const { loading: loadingNext, error: errorNext, data: dataNext, refetch: refetchNext } = useQuery(
    GET_OWN_SLOTS_QUERY,
    {
      variables: {
        profileId,
        procedureId,
        start: nextDayDate,
        searchType,
      },
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (!loadingCurrent && !loadingNext) {
      const currentSlots = dataCurrent?.GetOwnSlots || [];
      const nextDaySlots = dataNext?.GetOwnSlots || [];
      const combinedSlots = [...currentSlots, ...nextDaySlots];
      setAllSlots(combinedSlots);
    }
  }, [dataCurrent, dataNext, loadingCurrent, loadingNext]);

  const refetchAllData = () => {
    refetchCurrent();
    refetchNext();
  };

  if (errorCurrent || errorNext) return <div>Error loading slot data</div>;
  if (loadingCurrent || loadingNext) return <div>Loading slots...</div>;

  const mergedSlots = mergeSlots(allSlots, blackoutData?.ListBlackoutDates);

  const filteredSlots = mergedSlots.filter(slot => {
    const slotDate = moment(slot.start).format('YYYY-MM-DD');
    return dates.some(date => moment(date).format('YYYY-MM-DD') === slotDate);
  });

  const days = computeDaysFromSlots(filteredSlots) as { [key: string]: SlotsInterface[] };

  const formattedDates = dates.map((d: moment.Moment) => d.format(DATE_FORMAT));

  return (
    <Styles>
      <Row className="dates-bar">
        <Col span={24}>
          <DatesBar />
        </Col>
      </Row>
      <Row gutter={16} type="flex" justify="center" align="top" className="row-wrapper">
        <Col span={arrowsColSpan} className="left-arrow" />
        {formattedDates.map((date: string) => {
          const times = (days[date] || []) as SlotsInterface[];
          return (
            <Col key={date} span={slotsColSpan} className="date-wrapper text-center">
              {showToggle && (
                <Affix offsetTop={40}>
                  <Col span={24} className="bg-white bg-opacity-90">
                    {times.length > 0 && (
                      <div className="text-right">
                        <label className="text-xs">Available</label>
                      </div>
                    )}
                  </Col>
                </Affix>
              )}
              <TimeSlots times={times} scheduleId={scheduleId} refetchSlots={refetchAllData} showToggle={showToggle} />
            </Col>
          );
        })}
        <Col span={arrowsColSpan} className="right-arrow" />
      </Row>
    </Styles>
  );
};
