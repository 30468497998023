const UUID_V4_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

/**
 * Validates if a string is a valid UUID v4
 * @param value - The string to validate
 * @returns boolean indicating if the string is a valid UUID v4
 */
export const isValidUuidv4 = (value: string | undefined): boolean => {
  if (!value) return false;
  return UUID_V4_REGEX.test(value);
};

/**
 * Checks if an email address is valid and returns true if valid, false is invalid
 */
const isValidEmail = (email: string | null): boolean => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email !== null) {
    return regex.test(email.toLowerCase().trim());
  }

  return false;
};

/**
 * Checks if an email is valid and either returns an empty string if it's valid or the string 'invalid email address'
 * if it is invalid. Weird.
 * @deprecated Use isValidEmail instead. Only keeping because it's used in some spots.
 */
const validateEmail = (email: string | null): string => {
  if (!isValidEmail(email)) {
    return 'invalid email address';
  }

  return '';
};

export { validateEmail, isValidEmail };
