import _ from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Affix, Col, Collapse, Icon, Pagination, Row } from 'antd';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { ConsumerSchedulingMap } from './ConsumerSchedulingMap';
import { ConsumerSchedulingProfile } from './ConsumerSchedulingProfile';
import { defaultTheme } from '../../../themes/default';
import { generateDates, generateFutureDates, generatePastDates } from '../helpers/dates';
import { generateUtmParametersQueryString } from '../helpers/utm';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { SearchForm, SearchFormData } from './SearchForm';
import { ProfileSortType } from './SelectProfilesSort';
import {
  SearchProfilesInput,
  useSearchProfilesInOrganizationQuery,
} from '../../profiles/hooks/useSearchProfilesInOrganizationQuery';
import { SearchStepFilterBar } from './SearchStepFilterBar';
import { Slot } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';
import { StepContainer } from './StepContainer';
import { StepTitle } from './StepTitle';
import { useCountProfilesInOrganizationQuery } from '../../profiles/hooks/useCountProfilesInOrganizationQuery';
import { isValidUuidv4 } from '../../../helpers/formValidation';

interface Props {
  organization: Organization;
  params: Params;
}

interface Params {
  embedded?: string;
  language?: string;
  latitude?: string;
  location?: string;
  locationId?: string;
  longitude?: string;
  order?: string;
  payorId?: string;
  payorPlanId?: string;
  procedureId?: string;
  showSearch?: string;
  sort?: ProfileSortType;
  specialtyId?: string;
  utm_campaign?: string;
  utm_cmpid?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

const { Panel } = Collapse;

const Styles = styled.div`
  .ant-collapse-extra {
    float: left !important;
  }

  .map-container {
    border-top: 1px solid #d1d1d1;
    margin-top: 20px;
    padding-right: 20px;
    padding-top: 24px;
  }

  .no-search-results {
    color: ${({ theme }) => theme.blueDarkest};
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    height: auto;
    margin-top: 36px;
    text-align: center;
    text-decoration: none solid;
  }

  .pagination {
    margin-right: 36px;
    margin-top: 36px;
    text-align: right;
  }

  .search-collapse {
    background-color: ${({ theme }) => theme.white};
    padding-bottom: 20px;
  }

  .search-step-profile-row {
    border-top: 1px solid #d1d1d1;
    margin-top: 20px;
    padding-top: 24px;
  }
`;

const SearchIcon = (): JSX.Element => <Icon type="search" style={{ fontSize: '16px', paddingRight: '8px' }} />;

export const SearchStep: FC<Props> = ({ organization, params }): JSX.Element => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);
  const { singleSpecialtyId } = useContext(ConsumerSchedulingContext);

  const history = useHistory();

  const [dates, setDates] = useState<DateTime[]>(
    generateDates(DateTime.local(), organization.hasConsumerSchedulingShowWeekendsFeature)
  );
  const [searchProviderText, setSearchProviderText] = useState<string | undefined>(undefined);
  const [showMap, setShowMap] = useState<boolean | null>(
    organization.consumerSchedulingSettings?.showMap ? null : false
  );
  const [showWeekends, setShowWeekends] = useState<boolean>(organization.hasConsumerSchedulingShowWeekendsFeature);

  const [languageCode, setLanguageCode] = useState<string>(params.language ? params.language : '');

  const [latitude, setLatitude] = useState<number | undefined>(
    params?.latitude ? parseFloat(params.latitude) : undefined
  );
  const [longitude, setLongitude] = useState<number | undefined>(
    params?.longitude ? parseFloat(params.longitude) : undefined
  );

  const [locationId, setLocationId] = useState<string>(params.locationId ? params.locationId : '');
  const [searchPanel, setSearchPanel] = useState<string | string[]>(
    params.procedureId && params.showSearch !== 'true' ? '' : 'search-panel'
  );

  // todo make default sort configurable
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const defaultSort = latitude && longitude ? ProfileSortType.Distance : ProfileSortType.NextAvailability;
  const [sort, setSort] = useState<ProfileSortType>((params.sort as ProfileSortType) || defaultSort);

  const handleClickShowMap = (checked: boolean, event: MouseEvent): void => {
    event.stopPropagation();
    setShowMap(checked);
  };

  const handleClickShowWeekends = (checked: boolean, event: MouseEvent): void => {
    event.stopPropagation();
    setShowWeekends(checked);

    const newDates = generateDates(dates[0], checked);
    setDates(newDates);
  };

  const handleJumpToDate = (date: DateTime): void => {
    const newDates = generateDates(date, showWeekends);
    setDates(newDates);
  };

  const handlePagination = (page: number, pageSize?: number): void => {
    setPage(page);
    if (pageSize) setPageSize(pageSize);
  };

  const handleSearchCollapseChange = (key: string | string[]): void => {
    setSearchPanel(key);
  };

  const handleSearchFormSubmit = (values: SearchFormData): void => {
    setSearchPanel('');
    setSort(values.location ? ProfileSortType.Distance : ProfileSortType.NextAvailability);

    const specialtyIdQueryString = singleSpecialtyId
      ? `singleSpecialtyId=${singleSpecialtyId}`
      : `specialtyId=${values.specialtyId}`;

    let queryString = `?${specialtyIdQueryString}&procedureId=${values.procedureId}`;
    if (values.payorId) queryString += `&payorId=${values.payorId}`;
    if (values.payorPlanId) queryString += `&payorPlanId=${values.payorPlanId}`;
    if (latitude && longitude) queryString += `&latitude=${latitude}&longitude=${longitude}`;
    if (values.location) queryString += `&location=${encodeURIComponent(values.location)}`;
    if (params.order) queryString += `&order=${params.order}`;
    if (params.embedded === 'true') queryString += '&embedded=true';
    queryString += generateUtmParametersQueryString(params, queryString);

    history.replace(`/consumer/${organization.slug ? organization.slug : organization.id}/search${queryString}`);
  };

  const handleSelectProfileSlot = (profile: Profile, slot: Slot): void => {
    let queryString = params.embedded === 'true' ? '?embedded=true' : '';
    queryString += generateUtmParametersQueryString(params, queryString);

    history.push(`/consumer/${organization.slug ? organization.slug : organization.id}/confirm${queryString}`, {
      order: params.order,
      payorId: params.payorId ? params.payorId : undefined,
      payorPlanId: params.payorPlanId ? params.payorPlanId : undefined,
      procedureId: params.procedureId,
      profile,
      slot,
    });
  };

  const searchProfilesInput: SearchProfilesInput = {
    displayName: searchProviderText ? searchProviderText : undefined,
    hasConsumerScheduling: true,
    isActive: true,
    zipcode: params.location ? params.location : undefined,
    languageCode: languageCode ? languageCode : undefined,
    latitude: params?.latitude ? parseFloat(params.latitude) : undefined,
    longitude: params?.longitude ? parseFloat(params.longitude) : undefined,
    locationId: locationId ? locationId : undefined,
    organizationIsActive: true,
    payorId: isValidUuidv4(params.payorId) ? params.payorId : undefined,
    payorPlanId: isValidUuidv4(params.payorPlanId) ? params.payorPlanId : undefined,
    procedureId: isValidUuidv4(params.procedureId) ? params.procedureId : undefined,
    specialtyId: isValidUuidv4(params.specialtyId) ? params.specialtyId : undefined,
    sort,
  };

  const {
    data: profileCountData,
    error: profileCountError,
    loading: profileCountLoading,
  } = useCountProfilesInOrganizationQuery({
    variables: { organizationId: organization.id, searchProfilesInput },
  });

  const profileCount =
    !profileCountLoading && !profileCountError && profileCountData ? profileCountData.countProfilesInOrganization : 0;

  const { data, error, loading } = useSearchProfilesInOrganizationQuery({
    variables: { organizationId: organization.id, page, pageSize, searchProfilesInput },
  });

  const {
    data: allProfilesData,
    error: allProfilesError,
    loading: allProfilesLoading,
  } = useSearchProfilesInOrganizationQuery({
    variables: { organizationId: organization.id, page: null, pageSize: null, searchProfilesInput },
  });

  if (error) return <InternalServerErrorAlert error={error} />;
  if (allProfilesError) return <InternalServerErrorAlert error={allProfilesError} />;

  if (loading || !data || allProfilesLoading || !allProfilesData) {
    return <Spinner />;
  }

  const { searchProfilesInOrganization: profiles } = data;
  const { searchProfilesInOrganization: allProfiles } = allProfilesData;

  const locations = _.uniq(profiles.map(p => p.location.id));

  const locationsWithMapNumbers: { [key: string]: number } = {};

  // number locations
  locations.forEach((l, index) => (locationsWithMapNumbers[l] = index + 1));

  // add map numbers to profiles
  const numberedProfiles: (Profile & { mapNumber: number })[] = profiles.map(p => {
    return { ...p, mapNumber: locationsWithMapNumbers[p.location.id] };
  });

  const locationsForFilter = _.uniq(allProfiles.map(p => p.location));

  const onClickFuture = (): void => setDates(generateFutureDates(dates[0], showWeekends));
  const onClickPast = (): void => setDates(generatePastDates(dates[0], showWeekends));

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <StepContainer maxWidth={showMap === true || showMap === null ? '1920px' : '1248px'}>
          <Row gutter={[0, { xs: 24, sm: 28, md: 32, lg: 36 }]}>
            <Col span={24}>
              <StepTitle title="Schedule an Appointment" />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Collapse
                activeKey={searchPanel}
                bordered={false}
                className="search-collapse"
                onChange={handleSearchCollapseChange}
              >
                <Panel header="Search For Appointment Times" key="search-panel" extra={SearchIcon()} forceRender={true}>
                  <SearchForm
                    initialLocation={params?.location}
                    initialPayorId={params?.payorId}
                    initialPayorPlanId={params?.payorPlanId}
                    initialProcedureId={params?.procedureId}
                    initialSpecialtyId={params?.specialtyId}
                    onSubmit={handleSearchFormSubmit}
                    organization={organization}
                    setLatitude={setLatitude}
                    setLongitude={setLongitude}
                    embedded={params.embedded === 'true'}
                  />
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SearchStepFilterBar
                dates={dates}
                defaultLanguageCode={languageCode}
                defaultSearchProvider={searchProviderText}
                defaultShowWeekends={organization.hasConsumerSchedulingShowWeekendsFeature}
                disableSortByDistance={!latitude || !longitude}
                locationId={locationId}
                locations={locationsForFilter}
                onClickFuture={onClickFuture}
                onClickPast={onClickPast}
                onClickShowMap={handleClickShowMap}
                onClickShowWeekends={handleClickShowWeekends}
                onSearchProvider={searchText => setSearchProviderText(searchText)}
                onSelectLanguage={value => setLanguageCode(value)}
                onSelectLocation={value => setLocationId(value)}
                onSelectSort={value => setSort(value)}
                onSelectStart={handleJumpToDate}
                organization={organization}
                profiles={numberedProfiles}
                showMap={showMap}
                sort={sort}
              />
            </Col>
          </Row>
          <Row>
            {showMap === true || showMap === null ? (
              <Col xs={0} lg={showMap === true || showMap === null ? 6 : 0} className="map-container">
                <Affix>
                  <Row>
                    <Col span={24}>
                      <ConsumerSchedulingMap profiles={numberedProfiles} />
                    </Col>
                  </Row>
                </Affix>
              </Col>
            ) : null}
            <Col xs={24} lg={showMap === true || showMap === null ? 18 : 24}>
              {numberedProfiles.map(profile => (
                <Row className="search-step-profile-row" key={profile.id}>
                  <Col span={24}>
                    <ConsumerSchedulingProfile
                      dates={dates}
                      onSelectProfileSlot={handleSelectProfileSlot}
                      procedureId={params.procedureId ? params.procedureId : undefined}
                      profile={profile}
                      showMap={showMap === true || showMap === null}
                      onClickFuture={onClickFuture}
                      onClickPast={onClickPast}
                      handleJumpToDate={handleJumpToDate}
                    />
                  </Col>
                </Row>
              ))}
              {numberedProfiles.length === 0 ? (
                <Row type="flex" justify="center" align="middle">
                  <Col className="no-search-results">
                    <p>No providers were found matching your search criteria.</p>
                    <p>Please expand your search parameters and try again.</p>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <Row type="flex" justify="end" align="middle">
            <Col span={24} className="pagination">
              <Pagination current={page} onChange={handlePagination} pageSize={pageSize} total={profileCount} />
            </Col>
          </Row>
        </StepContainer>
      </Styles>
    </ThemeProvider>
  );
};
